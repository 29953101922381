<template>
  <div>
    <v-academy-list
      v-if="!editMode"
      @handleEditMode="handleEditMode"
    />
    <v-academy-form
      v-else
      :course="course"
      @handleEditMode="handleEditMode"
    />
  </div>
</template>
<script>
import AcademyList from '@/components/Academy/AcademyList'
import AcademyForm from '@/components/Academy/AcademyForm'

export default {
  components: {
    'v-academy-list': AcademyList,
    'v-academy-form': AcademyForm,
  },
  data() {
    return {
      editMode: false,
      course: null,
    }
  },
  methods: {
    handleEditMode({ show, course }) {
      this.course = course;
      this.editMode = show;
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
