<template>
  <div>
    <b-container fluid>
      <v-breadcrumbs :options="breadcrumbs" />
      <b-row>
        <b-col sm="8" md="9">
          <b-form-group>
            <label for="name">Nome do curso</label>
            <b-form-input
              id="name"
              autocomplete="off"
              v-model="form.name"
            />
            <div v-if="validate && !form.name" class="custom-invalid-feedback">Campo obrigatório</div>
          </b-form-group>
        </b-col>
        <b-col sm="4" md="3">
          <label for="thumbnail" class="thumbnail-btn">
            <v-image-svg class="icon" /> Adicionar thumbnail
          </label>
          <input
            type="file"
            id="thumbnail"
            ref="image"
            style="display: none"
            accept=".jpg, .png"
            @change="onFileChange"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="8" md="9">
          <b-form-group>
            <label for="specialty">Especialidade</label>
            <b-form-input
              id="specialty"
              autocomplete="off"
              v-model="form.specialty"
            />
            <div v-if="validate && !form.specialty" class="custom-invalid-feedback">Campo obrigatório</div>
          </b-form-group>
        </b-col>
        <b-col sm="4" md="3">
          <b-form-group>
            <label for="expiration_date">
              Data de expiração
              <span class="help">(opcional)</span>
            </label>
            <b-form-input
              id="expiration_date"
              type="date"
              v-model="form.expiration_date"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>

    <v-modules-form
      :form="form"
    />

    <div class="btn-footer">
      <b-button
        class="cancel-button"
        @click="closeEditMode"
      >
        Cancelar
      </b-button>
      <b-button
        variant="primary"
        @click="saveCourse"
      >
        Salvar curso
      </b-button>
    </div>
  </div>
</template>
<script>
import ImageSvg from '@/assets/icons/image.svg'
import Breadcrumbs from '@/components/General/Breadcrumbs'
import ModulesForm from '@/components/Academy/ModulesForm'
export default {
  components: {
    'v-image-svg': ImageSvg,
    'v-breadcrumbs': Breadcrumbs,
    'v-modules-form': ModulesForm
  },
  props: {
    course: Object,
  },
  data() {
    return {
      validate: false,
      form: this.course ?
        { ...this.course, expiration_date: this.course.expiration_date ? this.moment(this.course.expiration_date).format('YYYY-MM-DD') : null } :
        this.getDefaultForm(),
      file: null,
      breadcrumbs: [
        { label: 'Eyecare Academy', onClick: this.closeEditMode },
        { label: this.course ? this.course.name : 'Novo curso' },
      ]
    }
  },
  methods: {
    getDefaultForm() {
      return {
        name: '',
        thumbnail: '',
        specialty: '',
        expiration_date: '',
        modules: [],
      }
    },
    closeEditMode() {
      this.$emit('handleEditMode', { show: false, course: null });
    },
    isValid() {
      this.validate = true;
      return this.form.name &&
        this.form.specialty;
    },
    async saveCourse() {
      if (!this.isValid()) return;
      const loading = this.$loading.show();
      try {
        if (!this.form.id) {
          const res = await this.api.createCourse(this.form);
          this.file && this.saveCourseThumbnail(res.data.id)
        } else {
          await this.api.updateCourse(this.form.id, this.form);
          this.file && this.saveCourseThumbnail(this.form.id)
        }
        this.$toast.success('Curso salvo com sucesso');
        this.form = this.getDefaultForm();
        this.closeEditMode();
        loading.hide();
      } catch (err) {
        loading.hide();
        this.$toast.error(err.message);
      }
    },
    onFileChange(event) {
      var files = event.target.files || event.dataTransfer.files;
      if (!files.length)
        return;
      this.file = files[0];
    },
    async saveCourseThumbnail(courseId) {
      const dataForm = new FormData();
      dataForm.append(`file`, this.file);
      this.api.saveCourseThumbnail(courseId, dataForm)
        .catch(err => this.$toast.error(err.message));
    },
    watch: {
      course() {
        if (this.course && this.course.expiration_date) {
          this.form.expiration_date = this.course.expiration_date
        }
      }
    }
  },
}
</script>
<style lang="scss" scoped>
input {
  width: 100%;
}
.thumbnail-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 8px;
  border: 2px solid var(--blue-500);
  color: var(--blue-500);
  font-weight: 600;
  font-size: 18px;
  height: 38px;
  margin-top: 28px;
  cursor: pointer;

  &:hover {
    color: var(--blue-500);
  }
  .icon {
    width: 25px;
    height: 25px;
    stroke: var(--blue-500);
  }
}
.btn-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
  padding-right: 15px;
  gap: 20px;
}
</style>
