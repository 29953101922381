<template>
  <div class="module-wrapper">
    <div class="class-wrapper" v-for="(videoClass, index) in form.modules[moduleIndex].video_classes" :key="index">
      <div class="class-header">
        <div class="title">M{{ String((moduleIndex + 1)).padStart(2, '0') }} - Aula {{ String((index + 1)).padStart(2, '0') }}</div>
        <div class="actions">
          <v-icon :tooltip="`Remover aula ${String((index + 1)).padStart(2, '0')}`">
            <v-delete class="cursor" @click="removeVideoClass(index)" />
          </v-icon>
          <v-icon v-if="index === (form.modules[moduleIndex].video_classes.length - 1)" tooltip="Adicionar aula">
            <v-plus class="icon" @click="addVideoClass" />
          </v-icon>
          <v-chevron-down class="chevron" v-b-toggle="`collapse-class-${index}`" />
        </div>
      </div>
      <b-collapse visible :id="`collapse-class-${index}`">
          <b-row>
            <b-col>
              <b-form-group>
                <label for="class_name">Nome da aula</label>
                <b-form-input
                  id="class_name"
                  autocomplete="off"
                  v-model="videoClass.name"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <label for="class_link">Link da aula</label>
                <b-form-input
                  id="class_link"
                  autocomplete="off"
                  v-model="videoClass.path"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group>
                <label for="description">Descrição</label>
                <b-form-textarea
                  id="description"
                  class="textarea"
                  autocomplete="off"
                  rows="8"
                  v-model="videoClass.description"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group>
                <label for="references">Referências</label>
                <b-form-textarea
                  id="references"
                  class="textarea"
                  autocomplete="off"
                  rows="8"
                  v-model="videoClass.references"
                />
              </b-form-group>
            </b-col>
          </b-row>
      </b-collapse>
    </div>
    <div v-if="!form.modules[moduleIndex].video_classes.length" class="empty">
      <div>Nenhuma aula adicionada</div>
      <b-button variant="outline-primary" @click="addVideoClass">Adicionar aula</b-button>
    </div>
  </div>
</template>
<script>
import Delete from '@/assets/icons/delete.svg'
import Plus from '@/assets/icons/plus.svg'
import Icon from '@/components/General/Icon'
import ChevronDown from '@/assets/icons/chevron-down.svg'
export default {
  props: {
    form: Object,
    moduleIndex: Number,
  },
  components: {
    'v-delete': Delete,
    'v-plus': Plus,
    'v-icon': Icon,
    'v-chevron-down': ChevronDown,
  },
  methods: {
    addVideoClass() {
      const videoClasses = [ ...this.form.modules[this.moduleIndex].video_classes ] || [];
      videoClasses.push({
        name: '',
        path: 'https://player.vimeo.com/video/',
        description: '',
        references: '',
        position: videoClasses.length,
      })
      this.form.modules[this.moduleIndex].video_classes = videoClasses;
    },
    removeVideoClass(index) {

      const id = this.form.modules[this.moduleIndex].video_classes[index].id
      if (!id) {
        this.removeFromArray(index)
        return
      }

      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente excluir a <span>Aula ${String((index + 1)).padStart(2, '0')}</span>? Essa ação não poderá ser desfeita.</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
       }).then(res => {
        if (res.isConfirmed) {
          this.deleteVideoClass(index, id)
        }
       })
    },
    removeFromArray(index) {
      this.form.modules[this.moduleIndex].video_classes.splice(index, 1)
    },
    deleteVideoClass(index, id) {
      const loading = this.$loading.show()
      this.api.deleteVideoClass(id)
      .then(() => this.removeFromArray(index))
      .catch(err => this.$toast.error(err.message))
      .finally(() => { loading.hide() })
    }
  }
}
</script>
<style lang="scss" scoped>
.class-wrapper {
  padding: 0 20px;
  background: var(--neutral-100);
  border-radius: 8px;
  margin: 20px 0;
  .class-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;

    .title {
      font-weight: 600;
      font-size: 18px;
      margin: 0;
    }
    .actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;
    }

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 1%;
      fill: var(--blue-500);
      cursor: pointer;
    }

    .cursor {
      cursor: pointer;
    }

    .chevron {
      height: 25px;
      width: 25px;
      svg {
        stroke: var(--dark-blue);
      }
      &.not-collapsed {
        animation: rotateUp .5s;
        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
      }
      &.collapsed {
          animation: rotateDown .5s;
        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
      }

        @keyframes rotateUp {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(180deg);
        }
      }

      @keyframes rotateDown {
        0% {
            transform: rotate(180deg);
        }
        100% {
            transform: rotate(0deg);
        }
      }
    }
    .chevron:focus {
      outline: none !important;
    }
  }
}
.textarea {
  min-height: 200px
}
.empty {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
</style>
