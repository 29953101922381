<template>
  <b-container fluid class="module-wrapper">
    <div
      v-for="(_module, index) in form.modules || []"
      :key="index"
      class="module"
    >
      <div class="module-header">
        <div class="title">Módulo {{ String((index + 1)).padStart(2, '0') }}</div>
        <div class="actions">
          <v-icon :tooltip="`Remover módulo ${String((index + 1)).padStart(2, '0')}`">
            <v-delete class="cursor" @click="removeModule(index)" />
          </v-icon>
          <v-icon v-if="index === (form.modules.length - 1)" tooltip="Adicionar módulo">
            <v-plus class="icon" @click="addModule" />
          </v-icon>
          <v-chevron-down class="chevron" v-b-toggle="`collapse-module-${index}`" />
        </div>
      </div>
      <b-collapse visible :id="`collapse-module-${index}`" class="not-collapsed">
        <b-row>
          <b-col xs="12">
            <b-form-group>
              <label for="module_name">Nome do módulo</label>
              <b-form-input
                id="module_name"
                autocomplete="off"
                v-model="_module.name"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <v-video-classes-form
          :form="form"
          :moduleIndex="index"
        />
      </b-collapse>
    </div>
    <div v-if="!form.modules || !form.modules.length" class="empty">
      <div>Nenhum módulo adicionado</div>
      <b-button variant="outline-primary" @click="addModule">Adicionar módulo</b-button>
    </div>
  </b-container>
</template>
<script>
import Delete from '@/assets/icons/delete.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import Plus from '@/assets/icons/plus.svg'
import Icon from '@/components/General/Icon'
import VideoClassesForm from '@/components/Academy/VideoClassesForm'
export default {
  props: {
    form: Object,
  },
  components: {
    'v-delete': Delete,
    'v-chevron-down': ChevronDown,
    'v-plus': Plus,
    'v-icon': Icon,
    'v-video-classes-form': VideoClassesForm,
  },
  methods: {
    addModule() {
      const modules = [...this.form.modules] || [];
      modules.push({
        name: '',
        position: modules.length,
        video_classes: [],
      })
      this.form.modules = modules;
    },
    removeModule(index) {
      const id = this.form.modules[index].id
      if (!id) {
        this.removeFromArray(index)
        return
      }

      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente excluir o <span>Módulo ${String((index + 1)).padStart(2, '0')}</span>? Os videos internos também serão excluídos. Essa ação não poderá ser desfeita.</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
       }).then(res => {
        if (res.isConfirmed) {
          this.deleteModule(index, id)
        }
       })
    },
    removeFromArray(index) {
      this.form.modules.splice(index, 1);
    },
    deleteModule(index, id) {
      const loading = this.$loading.show()
      this.api.deleteModule(id)
      .then(() => this.removeFromArray(index))
      .catch(err => this.$toast.error(err.message))
      .finally(() => { loading.hide() })
    }
  }
}
</script>
<style lang="scss" scoped>
.module-wrapper {
  .module {
    border-top: 1px solid var(--neutral-300);
    .module-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;

      .title {
        font-weight: 600;
        font-size: 18px;
        margin: 0;
      }
      .actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
      }
      .icon {
        width: 20px;
        height: 20px;
        margin-right: 1%;
        fill: var(--blue-500);
        cursor: pointer;
      }

      .cursor {
        cursor: pointer;
      }

      .chevron {
        height: 25px;
        width: 25px;
        svg {
          stroke: var(--dark-blue);
        }
        &.not-collapsed {
          animation: rotateUp .5s;
          -webkit-animation-fill-mode: forwards;
          -moz-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
        }
        &.collapsed {
            animation: rotateDown .5s;
          -webkit-animation-fill-mode: forwards;
          -moz-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
        }

          @keyframes rotateUp {
          0% {
              transform: rotate(0deg);
          }
          100% {
              transform: rotate(180deg);
          }
        }

        @keyframes rotateDown {
          0% {
              transform: rotate(180deg);
          }
          100% {
              transform: rotate(0deg);
          }
        }
      }
      .chevron:focus {
        outline: none !important;
      }
    }
  }
  .empty {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
}
</style>
