<template>
  <div>
    <div class="top-bar">
      <v-input-search
        placeholder="Busque por um curso ou especialidade"
        v-model="query"
        debounce="300"
      />
      <b-button
        class="btn-new"
        variant="primary"
        @click="() => openEditMode(null)"
      >
        <v-plus class="icon-plus" /> Novo curso
      </b-button>
    </div>

    <div class="list-title">Lista de cursos</div>
    <div
      v-for="course in courses"
      :key="course.id"
      class="course-card"
    >
      <div class="course-header">
        <img :src="course.thumbnail" />
        <div class="props">
          <div class="title">{{ course.name }}</div>
          <div class="subtitle">{{ course.specialty }}</div>
        </div>
      </div>
      <div class="course-details">
        <div class="props">
          <div class="title">Módulos</div>
          <div class="subtitle">{{ String(course.modules.length).padStart(2, '0') }}</div>
        </div>
        <div class="props">
          <div class="title">Aulas</div>
          <div class="subtitle">{{ String(countClasses(course)).padStart(2, '0') }}</div>
        </div>
        <div class="props">
          <div class="title">Data de expiração</div>
          <div class="subtitle">{{ course.expiration_date ?  moment(course.expiration_date).format('DD/MM/YYYY') : 'Não expira' }}</div>
        </div>
        <div class="actions">
          <div class="status">
            {{ course.status && course.status === 'published' ? 'Postado' : 'Rascunho' }}
          </div>
          <toggle-button
            :sync="true"
            :width="40"
            :height="20"
            :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
            :value="course.status && course.status === 'published'"
            @input="updateCourseStatus(course)"
          />
          <v-icon tooltip="Ver relatórios">
            <v-chart class="icon pointer" @click="$router.push(`/admin/academy/course-dashboard/${course.id}`)" />
          </v-icon>
          <v-icon tooltip="Editar curso">
            <v-edit class="icon pointer" @click="openEditMode(course)" />
          </v-icon>
          <v-icon tooltip="Remover curso">
            <v-delete class="pointer" @click="removeCourse(course)" />
          </v-icon>
        </div>
      </div>
    </div>

    <div class="pagination-position">
      <b-pagination
        v-model="page"
        :total-rows="count"
        :per-page="limit"
        first-number
        last-number
        size="sm"
      />
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import Plus from '@/assets/icons/plus.svg'
import Chart from '@/assets/icons/pie-chart.svg'
import Edit from '@/assets/icons/edit.svg'
import Delete from '@/assets/icons/delete.svg'
import Icon from '@/components/General/Icon'
import InputSearch from '@/components/General/InputSearch'

export default {
  components: {
    'v-plus': Plus,
    'v-delete': Delete,
    'v-chart': Chart,
    'v-edit': Edit,
    'v-icon': Icon,
    'v-input-search': InputSearch,
  },
  data() {
    return {
      courses: [],
      query: null,
      page: 1,
      count: 0,
      limit: 0,
    }
  },
  mounted(){
    this.getCoursers()
  },
  methods: {
    moment,
    openEditMode(course) {
      this.$emit('handleEditMode', { show: true, course });
    },
    getCoursers() {
      this.api.getCoursers(this.page, this.query, true)
      .then(res => {
        this.courses = res.data.data
        this.count = res.data.total
        this.limit = res.data.per_page
      })
    },
    countClasses(course) {
      return course.modules.reduce((acc, m) => {
        acc += m.video_classes.length
        return acc
      }, 0)
    },
    updateCourseStatus(course) {
      const status = course.status === 'draft' ? 'published' : 'draft'
      const loading = this.$loading.show();
      this.api.updateCourse(course.id, { status })
      .then(() => {
        this.$toast.success('Status atualizado com sucesso')
        this.getCoursers()
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => loading.hide());
    },
    removeCourse(course) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente excluir o <span>Curso ${course.name}</span>? Os módulos e videos internos também serão excluídos. Essa ação não poderá ser desfeita.</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
       }).then(res => {
        if (res.isConfirmed) {
          this.deleteCourse(course.id)
        }
      })
    },
    deleteCourse(id) {
      const loading = this.$loading.show()
      this.api.deleteCourse(id)
      .then(() => {
        this.$toast.success('Curso removido com sucesso')
        this.getCoursers()
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => { loading.hide() })
    }
  },
  watch: {
    query() { this.getCoursers() },
    page() { this.getCoursers() },
  }
}
</script>
<style lang="scss" scoped>
.top-bar {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: 100%;
  height: 87px;
  padding: 0 20px;
  border-bottom: 1px solid var(--neutral-200);
  background-color: var(--neutral-000);
  position: relative;

  .btn-new {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    height: 38px;
    white-space: nowrap;
    .icon-plus {
      width: 20px;
      height: 20px;
      fill: var(--neutral-000);
    }
  }
}
.list-title {
  padding: 20px 0 0 20px;
  font-weight: 600;
  font-size: 18px;
  color: var(--type-active);
}
.course-card {
  border: 1px solid var(--neutral-200);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin: 20px;

  .course-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    img {
      width: 90px;
      height: 60px;
      border-radius: 4px;
      border: 1px solid var(--neutral-200);
      background: var(--neutral-200);
    }
  }

  .props {
    .title {
      font-weight: 400;
      font-size: 16px;
      margin-bottom: 0;
    }
    .subtitle {
      font-weight: 600;
      font-size: 14px;
    }
  }

  .course-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    white-space: nowrap;

    .actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      margin-left: 30px;

      .icon {
        width: 20px;
        height: 20px;
        stroke: var(--blue-500);
      }

      .pointer {
        cursor: pointer;
      }

      .status {
        width: 60px;
      }
    }
  }
}
</style>
